import React from 'react';
import { Link } from 'gatsby';

const PostListing = ({ posts }) => {
    return (
        <>
        {posts.map(({ node }, index) => {
          return (
            <article className="post" key={node.fields.slug}
            style={{
              backgroundColor: 'var(--bg)',
              color: 'var(--textNormal)',
              transition: 'color 0.2s ease-out, background 0.2s ease-out',
            }}
            >
              {node.frontmatter.cover &&
                  <Link
                    to={node.fields.slug}
                    className="post-thumbnail"
                    style={{
                      backgroundImage: `url(${node.frontmatter.cover})`,
                    }}
                  />
                }
              <div className="post-content">
                <h2 className="post-title">
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </h2>
                <p>{node.excerpt}</p>
                <span className="post-date">
                  {node.frontmatter.date}&nbsp;&nbsp;—&nbsp;
                </span>
                <span className="post-words">
                  {node.timeToRead} minute read
                </span>
              </div>
            </article>
          )
        })}
        </>
    )
}

export default PostListing;